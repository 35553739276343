//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-76:_8080,_7476,_4564,_3968,_7912,_3974,_5712,_4648;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-76')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-76', "_8080,_7476,_4564,_3968,_7912,_3974,_5712,_4648");
        }
      }catch (ex) {
        console.error(ex);
      }