//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-76:_4360,_7808,_3440,_6520,_2164,_1278,_1904,_5040;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-76')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-76', "_4360,_7808,_3440,_6520,_2164,_1278,_1904,_5040");
        }
      }catch (ex) {
        console.error(ex);
      }